<template>
    <div>
        <!-- begin::Entry -->
        <div class="d-flex flex-column flex-row-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="dashboard" activeTab="1"></UserNav>
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <div class="row">
                            <div class="col-xxl-6" v-if="coachFeed.length && coachFeed[0].videourl != ''">
                                <FeedBox :coachFeed=coachFeed />
                            </div>
                            <div class="col-xxl-6" v-else>
                                <div class="card card-custom gutter-b h300">
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                        <!--begin::Scroll-->
                                        <div>
                                            <!--begin::Messages-->
                                            <div class="messages">
                                                <!--begin::Message In-->
                                                <h5>
                                                    <div class="text-center center">
                                                        <p>
                                                            This session is offline!
                                                        </p>
                                                        <p class="pt-5">
                                                            <i class="fa fa-video-slash icon-2x"></i>
                                                        </p>
                                                    </div>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-xxl-6">
                            <div class="card card-custom gutter-b h200"
                                v-if="Object.keys( currentUser ).length && currentUser.isCoach && userProgramCoachDetail[0].coach_list[0].coach_list_id == coachFeed[0].coachlistid">
                                <!--begin::Header-->
                                <div class="card-header border-0">
                                    <h3 class="card-title font-weight-bolder text-dark">Session Description</h3>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0">
                                    <div class="d-block text-center">
                                        <div class="card-body pt-0">
                                            <!--begin::Body-->
                                            <div class="card-body py-0 pb-5">
                                                <!--begin::Top-->
                                                <div>
                                                    <p class="text-dark font-size-lg font-weight-normal">
                                                        {{ sessionTitle }}
                                                    </p>
                                                </div>
                                                <!--end::Top-->
                                                <form class="position-relative mt-5 text-left">
                                                    <label>Characters Remaining:
                                                        {{
                                                            detailForm.maxChars - detailForm.sessionDescription.length
                                                        }}</label>
                                                    <div class="input-group">
                                                        <input type="text" :maxlength="detailForm.maxChars"
                                                            v-model="detailForm.sessionDescription" class="form-control"
                                                            placeholder="Update Session Description" />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-secondary" type="button"
                                                                @click="addSessionDetail">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <!--end::Body-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-custom gutter-b h300">
                                <!--begin::Header-->
                                <div class="card-header border-0">
                                    <h3 class="card-title font-weight-bolder text-dark">Chat Transcript</h3>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0">
                                    <!--begin::Scroll-->
                                    <div>
                                        <!--begin::Messages-->
                                        <div class="messages">
                                            <!--begin::Message In-->
                                            <h5 v-if="chatHistory.length <= 0">
                                                <div class="text-center center">
                                                    <p>
                                                        There were no chats this session.
                                                    </p>
                                                    <p class="pt-5">
                                                        <i class="fa fa-comments icon-2x"></i>
                                                    </p>
                                                </div>
                                            </h5>
                                            <div v-else v-for="(chat, index) in chatHistory" :key="index"
                                                class="d-flex flex-column mb-5 align-items-start">
                                                <div class="d-flex align-items-center">
                                                    <!-- <div class="symbol symbol-circle symbol-40 mr-3">
                                        <img alt="Pic" :src="chat.user.profileimage" />
                                    </div> -->
                                                    <div>
                                                        <a class="
                        text-dark text-hover-primary
                        font-weight-bold font-size-h6
                        me-1
                      ">{{ chat.user.firstname }}&nbsp;</a>
                                                        <span class="text-dark font-size-sm">
                                                            <timeago :datetime="formatDate(chat.datetime)"></timeago>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="
                    mt-2
                    rounded
                    p-5
                    bg-light-success
                    text-dark
                    font-weight-bold font-size-lg
                    text-left
                    max-w-400px
                  ">
                                                    {{ chat.message }}
                                                </div>
                                            </div>
                                            <!--end::Message In-->
                                        </div>
                                        <!--end::Messages-->
                                        <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                                            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
                                        </div>
                                        <div class="ps__rail-y" style="top: 0px; right: -2px; height: 375px">
                                            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 133px"></div>
                                        </div>
                                    </div>
                                    <!--end::Scroll-->
                                </div>
                                <!--end: Card Body-->
                            </div>
                            <!--end: Card-->
                            <div class="card card-custom gutter-b h300">
                                <!--begin::Header-->
                                <div class="card-header border-0">
                                    <h3 class="card-title font-weight-bolder text-dark">Reported Session Issues</h3>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0">
                                    <div class="d-block text-center">
                                        <div class="card-body pt-0">
                                            <div v-if="videoIssues.length <= 0">
                                                <div class="text-center center">
                                                    <p class="fs-125">
                                                        There are no reported issues.
                                                    </p>
                                                    <p class="pt-5">
                                                        <i class="fa fa-bug icon-2x"></i>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--begin::Item-->
                                            <div v-else class="mb-6" v-for="(issue, index) in videoIssues" :key="index">
                                                <!--begin::Content-->
                                                <div class="d-flex align-items-center flex-grow-1">
                                                    <!--begin::Checkbox-->
                                                    <div class="symbol symbol-50px me-5">
                                                        <img :src="issue.user.profileimage" class="" alt="" />
                                                    </div>
                                                    <!--end::Checkbox-->
                                                    <!--begin::Section-->
                                                    <div class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                  w-100
                ">
                                                        <!--begin::Info-->
                                                        <div class="
                    d-flex
                    flex-column
                    align-items-cente
                    text-left
                    py-2
                    w-50
                  ">
                                                            <!--begin::Title-->
                                                            <a href="javascript:void()" class="
                      text-dark
                      font-weight-bold
                      text-hover-primary
                      font-size-lg
                      mb-1
                      ml-5
                    ">{{ issue.type }}</a>
                                                            <!--end::Title-->
                                                            <!--begin::Data-->
                                                            <span class="text-dark font-weight-bold ml-5">{{
                                                                issue.user.first
                                                            }}</span>
                                                            <!--end::Data-->
                                                        </div>
                                                        <!--end::Info-->
                                                        <!--begin::Label-->
                                                        <span class="
                    label label-lg label-light-primary label-inline
                    font-weight-bold
                    py-4
                  ">{{ formatDate(issue.datetime) }}</span>
                                                        <!--end::Label-->
                                                    </div>
                                                    <!--end::Section-->
                                                </div>
                                                <!--end::Content-->
                                            </div>
                                            <!--end::Item-->
                                        </div>
                                    </div>
                                </div>
                                <!--end: Card Body-->
                            </div>
                            <!--end: Card-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></template>

<script>
import FeedBox from "@/view/pages/feed/Feed.vue";
import UserNav from "@/view/layout/header/UserNav.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import * as fb from '../../../api/firebase.js';
import formateDateMixin from "@/mixins/formatDate"

export default {
    name: 'feeddetail',
    components: {
        UserNav,
        FeedBox
    },
    mixins: [formateDateMixin],
    data() {
        return {
            coachFeed: [],
            detailForm: {
                sessionDescription: "",
                maxChars: 200,
            },
            sessionTitle: "",
            currentUser: []
        }
    },
    async mounted() {
        this.$store.dispatch("getCurrentCoachDetail")
        this.$store.dispatch("getFeedById", {
            feedid: this.$route.params.feedId
        })
        this.$store.dispatch("getAdminVideoIssues", {
            sessionId: this.$route.params.sessionId,
            coachlistid: this.$route.params.coachlistId
        });
        this.$store.dispatch("getAdminChatHistory", {
            sessionId: this.$route.params.sessionId,
            coachlistid: this.$route.params.coachlistId
        });
        this.$store.dispatch("getAdminSessionDetail", {
            sessionId: this.$route.params.sessionId,
            coachlistid: this.$route.params.coachlistId
        });
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                })
            }
        }, (error) => { })

    },
    computed: {
        ...mapGetters(["individualFeed", "chatHistory", "videoIssues", "userProgramCoachDetail"])
    },
    watch: {
        individualFeed(newValue) {
            this.coachFeed = newValue
        },
        programSessionDetail(newValue, oldValue) {
            if (newValue && newValue.title !== "" && newValue.title !== null) {
                this.sessionTitle = newValue.title;
            } else {
                this.sessionTitle = "No Sessions Yet";
            }
        },
    },
    methods: {
        addSessionDetail() {
            if (this.detailForm.sessionDescription == "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a session description!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                const newDescription = this.detailForm.sessionDescription;
                this.$store.dispatch("addSessionDetail", {
                    title: newDescription,
                    sessionId: this.$route.params.sessionId,
                });
                this.coachFeed[0].title = newDescription;
                this.detailForm.sessionDescription = "";
                Swal.fire({
                    title: "",
                    text: "Your session title has been updated.",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            }
        },

    }
}
</script>

<style scoped>
body.modal-open {
    overflow: visible;
}

.h200 {
    height: 200px;
    overflow: hidden;
}

.h300 {
    height: 300px;
    overflow: scroll;
}

.fs-125 {
    font-size: 1.25rem;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.center {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>